<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div style="width: 100%;">
        <el-row justify="space-between" type="flex">
          <div style="width: 85%">
            <el-row :class="{ 'show-row': showRow }" class="Row-Input mb-3">
              <!-- 分类 -->
              <el-col :span="7">
                <el-form-item :label="$t('title.classify')">
                  <el-select v-model="form.typeName" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
                    <el-option
                      v-for="item in templateList"
                      :key="item.id"
                      :label="item.typeName"
                      :value="item.typeName"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- 平台 -->
              <el-col :span="8">
                <el-form-item :label="$t('title.platform')">
                  <el-select v-model="form.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
                    <el-option
                      v-for="item in platformList"
                      :key="item.id"
                      :label="item.platformCode"
                      :value="item.platformCode"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :span="24" class="mb-3">
              <el-col :span="24" style="display:flex;height:36px;">
                <el-button v-permission="'sortMange:sortAdd'" type="primary" @click="handleSort">{{ $t('title.addcategory') }}</el-button>
              </el-col>
            </el-row>
          </div>
          <el-row style="width: 15%">
            <el-col class="row-center">
              <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
              <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
            </el-col>
          </el-row>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      class="mb-3"
      max-height="500px"
    >
      <el-table-column
        :label="$t('page.No')"
        align="center"
        sortable
        type="index"
        width="80"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.classify')"
        align="center"
        prop="typeName"
        sortable
      />
      <el-table-column
        :label="$t('title.platform')"
        align="center"
        prop="platform"
        sortable
      />
      <el-table-column
        :label="$t('title.Quantity')"
        align="center"
        prop="typeCount"
        sortable
      >
        <template slot-scope="scope">
          <router-link :to="{ path: '/service-manage/Email-manage/Email-manage', query: { id: scope.row.id}}" style="color:#1890ff;">{{ scope.row.typeCount }}</router-link>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('page.operate')"
        align="center"
        fixed="right"
        width="150"
      >
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-button v-permission="'sortMange:sortAdd'" size="small" type="text" @click="handleEdit(scope.row)">{{ $t('title.Edit') }}</el-button>
          <!--移除-->
          <el-button v-permission="'sortMange:sortDelete'" size="small" type="text" @click="handleRemove(scope.row.id)">{{ $t('page.delete') }}</el-button>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[10,20, 50, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <el-dialog :title="$t('title.classify')" :visible.sync="dialogFormVisibleSort">
      <el-form :model="formSort" label-width="70px">
        <el-form-item :label="$t('title.platform')">
          <el-select v-model="formSort.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable multiple>
            <el-option
              v-for="item in platformList"
              :key="item.id"
              :label="item.platformCode"
              :value="item.platformCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('title.classify')">
          <el-input v-model="formSort.typeName" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleSort = false">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="submitSort">{{ $t('title.Save') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryTerraceList } from '@/api/omsorder'
import {
  deleteTemplateTypeById,
  getAllTemplateType,
  getTemplateType,
  insertTemplateType,
  updateTemplateTypeById
} from '@/api/service-management'
import mixin from '@/mixin/oms-mixin'

export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      showRow: false,
      TableLoading: false,
      tableDatas: [],
      form: {
        typeName: '',
        platform: ''
      },
      formSort: {
        typeName: '',
        platform: ''
      },
      buOption: [],
      platformList: [],
      dialogFormVisibleSort: false,
      templateList: []

    }
  },
  computed: {
    queryParameter() {
      return Object.assign({}, this.form, this.pager)
    }
  },
  watch: {
    'dialogFormVisibleSort'(val) {
      if (!val) {
        this.formSort = this.$options.data().formSort
      }
    }
  },

  mounted() {
    this._getTemplateType() // 获取列表
    this._queryTerraceList() // 获取平台
    this._getAllTemplateType()
  },
  methods: {
    handleSort() {
      this.dialogFormVisibleSort = true
    },
    async _getAllTemplateType() {
      const { datas } = await getAllTemplateType()
      this.templateList = datas
    },
    // 获取平台信息
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    platformChange() {
      this.getType({ platform: this.form.platform })
      this.query()
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._getTemplateType(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = {}
      this._getTemplateType(1)
    },
    // 点击展开控制文本框的显示隐藏
    showRowClick() {
      this.showRow = !this.showRow
    },
    // 默认查询
    async _getTemplateType(params) {
      try {
        this.TableLoading = true
        const { datas } = await getTemplateType(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._getTemplateType(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._getTemplateType(this.queryParameter)
    },
    // 编辑 修改分类
    handleEdit(row) {
      console.log(this.formSort)
      this.dialogFormVisibleSort = true
      const { typeName, platform, id } = row
      this.formSort = { typeName, platform: platform.split(','), id }
      console.log(this.formSort)
    },
    // 提交
    submitSort() {
      console.log(this.formSort)
      if (this.formSort.id) {
        this._updateTemplateTypeById({ id: this.formSort.id, typeName: this.formSort.typeName, platform: this.formSort.platform.join(',') })
      } else {
        this._insertTemplateType({ typeName: this.formSort.typeName, platform: this.formSort.platform.join(',') })
      }
    },
    // 编辑
    async _updateTemplateTypeById(params) {
      try {
        this.AssignBuLoding = true
        const { code, msg } = await updateTemplateTypeById(params)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._getTemplateType(this.queryParameter)
      } finally {
        this.AssignBuLoding = false
        this.dialogFormVisibleSort = false
        Object.assign(this.formSort, this.$options.data.call(this).formSort)
      }
      Object.assign(this.formSort, this.$options.data.call(this).formSort)
    },
    // 新增
    async _insertTemplateType(params) {
      try {
        this.AssignBuLoding = true
        const { code, msg } = await insertTemplateType(params)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this._getTemplateType(this.queryParameter)
      } finally {
        this.AssignBuLoding = false
        this.dialogFormVisibleSort = false
        Object.assign(this.formSort, this.$options.data.call(this).formSort)
      }
    },
    handleRemove(row) {
      this.$confirm('删除分类后，会将分类下所有模板一并删除，请谨慎操作,是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this._deleteTemplateTypeById(row)
        this._getTemplateType(this.queryParameter)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
        this._getTemplateType(this.queryParameter)
      })
    },
    // 删除店铺信息
    async _deleteTemplateTypeById(row) {
      const { code, msg } = await deleteTemplateTypeById(row)
      if (code === 0) {
        this.$message({
          type: 'success',
          message: msg
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
